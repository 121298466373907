<template>
  <div>
    <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">{{ $t('water_change.water_change') }}</h2>

    <water-change-form/>
  </div>
</template>

<script>
import WaterChangeForm from "@/components/Farm/WaterChange/WaterChangeForm";
export default {
  name: "InputWaterChange",
  components: {WaterChangeForm},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object,
      required: false,
    },
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    }
  },
  data: () => {
    return {
      form: {
        selectedBlocks: [],
        selectedPonds: [],
        value: null
      },

      selected: [],
    }
  },
  watch: {
    selectedFarm: {
      deep: true,
      handler() {
        this.resetSelectedForm()
      }
    }
  },
  created() {
    this.resetSelectedForm()
  },
  methods: {
    resetSelectedForm() {
      if (this.defaultValue) {
        this.form = {
          value: this.defaultValue.value,
          created_at: new Date(this.defaultValue.created_at)
        }
      } else {
        this.form.value = null
        this.form.selectedBlocks = []
        this.form.selectedPonds = []
        this.selected = []
        this.selected = this.selectedFarm.blocks.map(e => {
          let ponds = this.selectedFarm.ponds.filter(f => f.block_id === e.block_id)
          return {
            block_id: e.block_id,
            block_name: e.block_name,
            value: false,
            ponds: ponds.map(f => {
              return {
                pond_id: f.pond_id,
                name: f.name,
                value: false,
                status: f.status,
              }
            }),
          }
        })
      }
    },

    toggleSelectBlock(key) {
      this.selected[key].ponds.map(e => {
        if (e.status > 0) e.value = this.selected[key].value
        return e
      })
    },

    toggleSelectPond(key) {
      let selected = true

      this.selected[key].ponds.forEach(pond => {
        if (!pond.value) {
          selected = false
        }
      })

      this.selected[key].value = selected
    },

    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()
          if (this.defaultValue && this.defaultValue.cycle_id) {
            await this.$store.dispatch('farm/updateWaterChange', {
              water_change_id: this.defaultValue.water_change_id,
              cycle_id: this.defaultValue.cycle_id,
              value: this.form.value,
              created_at: this.$timestamp(this.form.created_at)
            })
          } else {
            let selected_ponds = this.selected.reduce((a, b) => {
              a = a.concat(b.ponds.filter(e => e.value))
              return a
            }, [])

            for (let i = 0; i < selected_ponds.length; i++) {
              let pond = selected_ponds[i]

              await this.$store.dispatch('farm/getActiveCycle', pond.pond_id).then(async res => {
                if (res && res.cycle_id) {
                  await this.$store.dispatch('farm/addWaterChange', {
                    cycle_id: res.cycle_id,
                    value: this.form.value,
                    created_at: this.$timestamp(this.form.created_at),
                  })
                }
              })
            }
          }
          this.resetSelectedForm()
          this.$loading(false)
          this.$emit('close')
          this.$store.dispatch('farm/refreshOverview')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 900px !important;
  max-width: 100%;
}
</style>

