<template>
  <div :class="{
    'is-shadowless': !isModal,
    'card': isModal,
  }">
    <div :class="{
      'card-content': isModal,
    }">
      <header class="mb-5" v-if="isModal">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ $t('water_change.water_change') }}</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
        <div>
          <p class="has-text-secondary mb-3">{{ selectedFarm.name }}</p>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form class="mt-3" @submit.prevent="handleSubmit">
            <div class="columns is-tablet">
              <div class="column is-half-tablet">
                <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('date')"
                           :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-datepicker
                        :placeholder="`${$t('select')} ${$t('date')}`"
                        icon="calendar-text"
                        locale="id-ID"
                        v-model="form.created_at"
                    >
                    </b-datepicker>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-half-tablet">
                <ValidationProvider :name="$t('water_change.total')" rules="required|double" v-slot="{ errors }">
                  <b-field :label="$t('water_change.total')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input v-model.number="form.value" type="number" step="any" :placeholder="`Input ${$t('water_change.total')}`" class="w-100"/>
                    <p class="control">
                      <b-button type="is-text" class="btn-placeholder">cm</b-button>
                    </p>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>

            <div v-if="!defaultValue">
              <p class="mb-3">{{ $t('select') }} {{ $t('farm.pond') }}</p>

              <div class="columns is-tablet">
                <div class="column is-half-tablet" v-for="(block, key) in selected" :key="key">
                  <div class="mb-3">
                    <div v-if="block.ponds.length">
                      <b-field class="mb-0">
                        <b-checkbox v-model="block.value" @input="toggleSelectBlock(key)" :disabled="!block.ponds.find(e => e.status)">
                          {{ $t('farm.block') }} {{ block.block_name }}
                        </b-checkbox>
                      </b-field>

                      <div class="is-divider mt-2"></div>

                      <div class="columns is-tablet">
                        <div class="column is-half-tablet" v-for="(pond,k) in block.ponds" :key="k">
                          <div class="card is-bordered" :class="{
                      'has-background-primary-light has-border-primary': pond.value
                      }">
                            <label class="cursor-pointer">
                              <div class="card-content px-4 py-4 is-flex">
                                <b-field class="mb-0 is-flex">
                                  <b-checkbox
                                      :disabled="pond.status === 0"
                                      v-model="pond.value" @input="toggleSelectPond(key)">{{ pond.name }}
                                  </b-checkbox>
                                </b-field>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p v-else>{{ $t('farm.block') }} {{ block.block_name }} {{ $t('farm.no_pond') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </ValidationObserver>
      </section>
      <footer class="has-text-right">
        <b-button type="is-secondary is-light" @click="$emit('close')" v-if="isModal">{{ $t('cancel') }}</b-button>
        <b-button type="is-primary is-gradient" class="ml-2" @click.prevent="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "WaterChangeForm",
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Object,
      required: false,
    },
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    }
  },
  data: () => {
    return {
      form: {
        selectedBlocks: [],
        selectedPonds: [],
        value: null
      },

      selected: [],
    }
  },
  watch: {
    selectedFarm: {
      deep: true,
      handler() {
        this.resetSelectedForm()
      }
    }
  },
  created() {
    this.resetSelectedForm()
  },
  methods: {
    resetSelectedForm() {
      if (this.defaultValue) {
        this.form = {
          value: this.defaultValue.value,
          created_at: new Date(this.defaultValue.created_at)
        }
      } else {
        this.form.value = null
        this.form.selectedBlocks = []
        this.form.selectedPonds = []
        this.selected = []
        this.selected = this.selectedFarm.blocks.map(e => {
          let ponds = this.selectedFarm.ponds.filter(f => f.block_id === e.block_id)
          return {
            block_id: e.block_id,
            block_name: e.block_name,
            value: false,
            ponds: ponds.map(f => {
              return {
                pond_id: f.pond_id,
                name: f.name,
                value: false,
                status: f.status,
              }
            }),
          }
        })
      }
    },

    toggleSelectBlock(key) {
      this.selected[key].ponds.map(e => {
        if (e.status > 0) e.value = this.selected[key].value
        return e
      })
    },

    toggleSelectPond(key) {
      let selected = true

      this.selected[key].ponds.forEach(pond => {
        if (!pond.value) {
          selected = false
        }
      })

      this.selected[key].value = selected
    },

    handleSubmit() {
      this.$refs['form'].validate().then(async success => {
        if (success) {
          this.$loading()
          if (this.defaultValue && this.defaultValue.cycle_id) {
            await this.$store.dispatch('farm/updateWaterChange', {
              water_change_id: this.defaultValue.water_change_id,
              cycle_id: this.defaultValue.cycle_id,
              value: this.form.value,
              created_at: this.$timestamp(this.form.created_at)
            })
          } else {
            let selected_ponds = this.selected.reduce((a, b) => {
              a = a.concat(b.ponds.filter(e => e.value))
              return a
            }, [])

            let payload = []
            let timediff = new Date().getTimezoneOffset() * -1

            for (let i = 0; i < selected_ponds.length; i++) {
              let pond = selected_ponds[i]

              await this.$store.dispatch('farm/getActiveCycle', pond.pond_id).then(async res => {
                if (res && res.cycle_id) {
                  payload.push({
                    block_id: pond.block_id,
                    cycle_id: res.cycle_id,
                    value: this.form.value,
                    created_at: this.$timestamp(this.form.created_at),
                    date: this.$startDay(this.form.created_at).valueOf(),
                    timediff,
                  })
                }
              })
            }

            await this.$store.dispatch('farm/addWaterChange', payload)
          }
          this.resetSelectedForm()
          this.$loading(false)
          this.$emit('close')
          this.$store.dispatch('farm/refreshOverview')

          if (!this.isModal) this.$router.push('/farm/' + this.selectedFarm.farm_id)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 900px !important;
  max-width: 100%;
}
</style>

