var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'is-shadowless': !_vm.isModal,
  'card': _vm.isModal,
}},[_c('div',{class:{
    'card-content': _vm.isModal,
  }},[(_vm.isModal)?_c('header',{staticClass:"mb-5"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v(_vm._s(_vm.$t('water_change.water_change')))]),(_vm.isModal)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1):_vm._e()]),_c('div',[_c('p',{staticClass:"has-text-secondary mb-3"},[_vm._v(_vm._s(_vm.selectedFarm.name))])])]):_vm._e(),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-tablet"},[_c('div',{staticClass:"column is-half-tablet"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('date'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":((_vm.$t('select')) + " " + (_vm.$t('date'))),"icon":"calendar-text","locale":"id-ID"},model:{value:(_vm.form.created_at),callback:function ($$v) {_vm.$set(_vm.form, "created_at", $$v)},expression:"form.created_at"}})],1)]}}])})],1),_c('div',{staticClass:"column is-half-tablet"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('water_change.total'),"rules":"required|double"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('water_change.total'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{staticClass:"w-100",attrs:{"type":"number","step":"any","placeholder":("Input " + (_vm.$t('water_change.total')))},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", _vm._n($$v))},expression:"form.value"}}),_c('p',{staticClass:"control"},[_c('b-button',{staticClass:"btn-placeholder",attrs:{"type":"is-text"}},[_vm._v("cm")])],1)],1)]}}])})],1)]),(!_vm.defaultValue)?_c('div',[_c('p',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('select'))+" "+_vm._s(_vm.$t('farm.pond')))]),_c('div',{staticClass:"columns is-tablet"},_vm._l((_vm.selected),function(block,key){return _c('div',{key:key,staticClass:"column is-half-tablet"},[_c('div',{staticClass:"mb-3"},[(block.ponds.length)?_c('div',[_c('b-field',{staticClass:"mb-0"},[_c('b-checkbox',{attrs:{"disabled":!block.ponds.find(function (e) { return e.status; })},on:{"input":function($event){return _vm.toggleSelectBlock(key)}},model:{value:(block.value),callback:function ($$v) {_vm.$set(block, "value", $$v)},expression:"block.value"}},[_vm._v(" "+_vm._s(_vm.$t('farm.block'))+" "+_vm._s(block.block_name)+" ")])],1),_c('div',{staticClass:"is-divider mt-2"}),_c('div',{staticClass:"columns is-tablet"},_vm._l((block.ponds),function(pond,k){return _c('div',{key:k,staticClass:"column is-half-tablet"},[_c('div',{staticClass:"card is-bordered",class:{
                    'has-background-primary-light has-border-primary': pond.value
                    }},[_c('label',{staticClass:"cursor-pointer"},[_c('div',{staticClass:"card-content px-4 py-4 is-flex"},[_c('b-field',{staticClass:"mb-0 is-flex"},[_c('b-checkbox',{attrs:{"disabled":pond.status === 0},on:{"input":function($event){return _vm.toggleSelectPond(key)}},model:{value:(pond.value),callback:function ($$v) {_vm.$set(pond, "value", $$v)},expression:"pond.value"}},[_vm._v(_vm._s(pond.name)+" ")])],1)],1)])])])}),0)],1):_c('p',[_vm._v(_vm._s(_vm.$t('farm.block'))+" "+_vm._s(block.block_name)+" "+_vm._s(_vm.$t('farm.no_pond')))])])])}),0)]):_vm._e()])])],1),_c('footer',{staticClass:"has-text-right"},[(_vm.isModal)?_c('b-button',{attrs:{"type":"is-secondary is-light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary is-gradient"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }